import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/auth',
    component: () => import('@/layout/AuthLayout.vue'),
    children: [
      {
        path: 'login/',
        name: 'AuthLogin',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue')
      },
      {
        path: 'logout/',
        name: 'AuthLogout',
        redirect: () => {
          return store.dispatch('logOut')
            .then(() => ({ name: 'login' }));
        }
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layout/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'ProjectsList',
        component: () => import(/* webpackChunkName: "cases" */ '../views/project/ProjectsList.vue')
      },
      {
        path: 'project/:id',
        props: true,
        name: 'Project',
        component: () => import(/* webpackChunkName: "cases" */ '../views/project/Project.vue'),
      },
      {
        path: 'project/:projectId/docsearch',
        props: true,
        name: 'ProjectDocSearchView',
        component: () => import(/* webpackChunkName: "cases" */ '../views/project/ProjectDocSearch.vue'),
      },
      {
        path: 'project/:id/info',
        props: true,
        name: 'ProjectInfo',
        component: () => import(/* webpackChunkName: "cases" */ '../views/project/ProjectInfo.vue'),
      },
      {
        path: 'cases/',
        name: 'CasesView',
        component: () => import(/* webpackChunkName: "cases" */ '../views/CasesView.vue')
      },
      {
        path: 'case/:id',
        component: () => import(/* webpackChunkName: "casesFrame" */ '../views/case/CaseFrame.vue'),
        name: 'CaseView',
        props: true,
        children: [
          {
            path: 'document/:document_id',
            name: 'DocumentView',
            component: () => import(/* webpackChunkName: "case" */ '../views/DocumentView.vue'),
            props: true
          },
          {
            path: 'project/:project_id',
            name: 'ProjectView',
            component: () => import(/* webpackChunkName: "case" */ '../views/case/ProjectView.vue'),
            props: true
          }
        ]
      },
      {
        path: 'mywork/',
        name: 'MyWorkView',
        component: () => import(/* */ '../views/MyWorkView.vue')
      },
      {
        path: 'mysettings/',
        name: 'UserView',
        component: () => import(/* */ '../views/UserView.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
